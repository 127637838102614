import { DateTime } from 'luxon';

export const convertStringDateToIsoString = (dateString: string): string => {
  let dt: DateTime;

  // Attempt various parsing formats explicitly
  dt = DateTime.fromFormat(dateString, "MMMM d, yyyy HH:mm:ss 'GMT'ZZ", {
    setZone: true,
  });

  if (!dt.isValid) {
    dt = DateTime.fromISO(dateString, { setZone: true });
  }
  if (!dt.isValid) {
    dt = DateTime.fromRFC2822(dateString, { setZone: true });
  }
  if (!dt.isValid) {
    dt = DateTime.fromFormat(dateString, 'M/d/yyyy HH:mm:ss z', {
      setZone: true,
    });
  }
  if (!dt.isValid) {
    dt = DateTime.fromFormat(dateString, 'M/d/yyyy', { zone: 'UTC' });
  }
  if (!dt.isValid) {
    // If the string doesn't contain a timezone, force UTC
    dt = DateTime.fromFormat(dateString, 'LLL d yyyy hh:mm a', { zone: 'UTC' });
  }
  if (!dt.isValid) {
    // Final fallback to JS Date object with UTC assumption
    const date = new Date(dateString);

    dt = DateTime.fromISO(date.toISOString(), { setZone: false });
  }

  if (!dt.isValid) {
    throw new Error(`Invalid date string: ${dateString}`);
  }

  // Return the ISO string
  return String(dt.toISO());
};

export const ToDate = (
  date: string | DateTime | Date,
  hasTimezone: boolean = false
): DateTime => {
  if (typeof date === 'string') {
    const convertedDateString = convertStringDateToIsoString(date);
    return DateTime.fromISO(convertedDateString, { setZone: true });
  }

  if (date instanceof Date) {
    let dateString = date.toJSON();
    if (hasTimezone) {
      dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    const convertedDateString = convertStringDateToIsoString(dateString);
    return DateTime.fromISO(convertedDateString, { setZone: true });
  }
  return date;
};

export const ToDateTime = (date: string | DateTime | Date): DateTime => {
  if (typeof date === 'string') {
    // Strip the timezone information and interpret the date/time as local
    const dateWithoutZone = date.substring(0, 19); // Strip everything after the seconds part
    return DateTime.fromISO(dateWithoutZone, { zone: 'UTC' });
  } else if (date instanceof Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // JS months are 0-indexed
    const day = date.getUTCDate();

    // Manually construct the DateTime object and add the timezone offset to adjust it
    return DateTime.utc(year, month, day);
  } else if (date instanceof DateTime) {
    // If it's already a Luxon DateTime, just return it
    return date;
  }

  throw new Error('Invalid date type');
};

export const MAX_DATE = DateTime.fromISO('9999-12-31');
