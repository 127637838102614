// actions/GroupChangeAction.ts
import actionResponse, { ActionResponse } from '../ActionResponse';
import { DateTime } from 'luxon';
import { GroupView } from '../../views/GroupView';
import React from 'react';

export class GroupChangeAction {
  Execute(
    e: any | DateTime,
    selectedGroup: GroupView,
    setSelectedGroup: React.Dispatch<React.SetStateAction<GroupView>>
  ): ActionResponse {
    try {
      const group: any = { ...selectedGroup };
      if (DateTime.isDateTime(e)) {
        group.startDate = e;
      } else {
        group[e.currentTarget.id] = e.currentTarget.value;
      }
      setSelectedGroup({ ...group });
      return actionResponse.Success('Group changed successfully');
    } catch (error) {
      return actionResponse.HandleError(error);
    }
  }
}
