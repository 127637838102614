import { RoleStrings } from '../enums/Role';

export interface ParticipantView {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
  role: string;
  isNew: boolean;
  CohortUser: { id: number };
}

const empty: ParticipantView = {
  id: 0,
  name: '',
  email: '',
  avatarUrl: '',
  role: RoleStrings.Participant,
  isNew: false,
  CohortUser: { id: 0 },
};

const defaultExport = { Empty: empty };
export default defaultExport;
