import { IApiService } from './IApiService';
import { ApiService } from './ApiService';
import apiResponse from './ApiResponse';
import { SectionView } from '../views/DashboardView';

export class OnboardingSurveyResponseStore {
  constructor(private readonly api: IApiService = new ApiService()) {}

  public async Write(section: SectionView, groupId: number) {
    try {
      const response = await this.api.post(
        `cohort/${groupId}/section/responses`,
        section
      );
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
