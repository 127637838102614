import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import React, { FC } from 'react';
import { FeedbackFish } from '@feedback-fish/react';
import { useCookies } from 'react-cookie';

interface DebugPanelProps {
  displayItem: any;
}

export const DebugPanel: FC<DebugPanelProps> = ({ displayItem }) => {
  const [cookies] = useCookies(['auth', 'profile']);
  if (process.env.NODE_ENV !== 'development') return null;
  return (
    <Accordion>
      <AccordionSummary>Debug</AccordionSummary>
      <AccordionDetails>
        <FeedbackFish projectId="2f352c37eef603" userId={cookies.profile.email}>
          <Button>Send feedback</Button>
        </FeedbackFish>
        <pre>{JSON.stringify(displayItem, null, 2)}</pre>
      </AccordionDetails>
    </Accordion>
  );
};
