import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth, Profile } from '../models';

const initialState: Auth = {
  token: '',
  profile: {
    email: '',
    avatarUrl: '',
    firstName: '',
    lastName: '',
    name: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    receivedGoogleAuthToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    receivedProfile(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
    },
  },
});

export const { receivedGoogleAuthToken, receivedProfile } = authSlice.actions;
export default authSlice.reducer;
