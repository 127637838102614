import { Validator } from 'fluentvalidation-ts';

export class InviteCodeValidator extends Validator<{
  inviteCode: string | null;
}> {
  constructor() {
    super();

    this.ruleFor('inviteCode')
      .notEmpty()
      .withMessage('Invalid invite code')
      .notNull()
      .withMessage('Invalid invite code');
  }
}
