import { TaskView } from './TaskView';
import { LeaderView } from './LeaderView';
import teamView, { TeamView } from './TeamView';
import { DateTime } from 'luxon';
import { MAX_DATE } from '../utils/DateUtils';

export type DashboardView = {
  pledgeAmount: number;
  cohortUserId: number;
  groupChatId: string;
  groupId: number;
  groupName: string;
  leaders: LeaderView;
  logo: string;
  progress: { [goalId: number]: number[] };
  tasks: TaskView[];
  team: TeamView;
  section: SectionView;
  needPayment: boolean;
  potAmount: number;
  groupStartDate: DateTime | string;
  availableJailCards: {
    id: number;
    cohortUserId: number;
    used: boolean;
    usedOn: Date;
  }[];
};
export type GoalSummaryView = {
  id: number;
  goalText: string;
  activity: number;
  duration: number;
  frequency: number;
  timeframe: number;
  startDate: DateTime;
  endDate: DateTime;
  goalsComplete: number;
};
export type SectionView = {
  id: number;
  title: string;
  description: string;
  questions: QuestionView[];
};

export type QuestionView = {
  id: number;
  name: string;
  text: string;
  responses: ResponseView[];
};

export type ResponseView = {
  responseType:
    | 'MutuallyExclusive'
    | 'MultiSelect'
    | 'FreeText'
    | 'Goal'
    | 'AccountabilityPartner';
  id: number;
  text: string;
  selected: boolean;
  textResponse: string;
};

const empty: DashboardView = {
  groupStartDate: MAX_DATE,
  cohortUserId: 0,
  potAmount: 0,
  logo: '',
  groupName: '',
  groupId: 0,
  progress: [],
  leaders: {},
  tasks: [],
  team: teamView.Empty,
  groupChatId: '',
  pledgeAmount: 0,
  needPayment: false,
  section: {
    id: 0,
    questions: [],
    title: '',
    description: '',
  },
  availableJailCards: [],
};
export const valueToActivityString = (value: number) => {
  const activityMapping: Record<string, string> = {
    '8': 'Skiing',
    '9': 'Running',
    '10': 'Climbing',
    '11': 'Hiking',
    '12': 'Canoeing',
    '13': 'Kayaking',
    '14': 'Cycling',
    '15': 'Mountain Biking',
    '16': 'Swimming',
    '17': 'Surfing',
    '18': 'Snowboarding',
    '19': 'Stand-up Paddleboarding',
    '20': 'Rowing',
    '21': 'Trail Running',
    '22': 'Walking',
    '23': 'Nordic Walking',
    '24': 'Inline Skating ',
    '25': 'Ice Skating',
    '26': 'Roller Skating',
    '27': 'Scootering',
    '28': 'Skateboarding',
    '29': 'Tennis',
    '30': 'Basketball',
    '31': 'Soccer',
    '32': 'Volleyball ',
    '33': 'Baseball',
    '34': 'Softball',
    '35': 'Football',
    '36': 'Rugby',
    '37': 'Lacrosse',
    '38': 'Golf',
    '39': 'Badminton',
    '40': 'Ping Pong',
    '41': 'Cricket',
    '42': 'Fencing',
    '43': 'Boxing',
    '44': 'Martial Arts',
    '45': 'Yoga',
    '46': 'Pilates',
    '47': 'CrossFit',
    '48': 'HIIT Class',
    '49': 'Treadmill Walking',
    '50': 'Treadmill Running',
    '51': 'Elliptical',
    '52': 'Stationary Biking',
    '53': 'Rowing Machine',
    '54': 'Stair Climber',
    '55': 'Weightlifting',
    '56': 'Dance Class',
    '57': 'Zumba',
    '58': 'Strength Training',
    '59': 'Aerobic Activity',
  };

  return activityMapping[value] || 'Unknown Value';
};

export const valueToString = (value: number) => {
  const menuMapping: any = {
    '1': 'Times per Week',
    '2': 'Minutes',
  };
  return menuMapping[value] || 'Unknown Value';
};
const defaultExport = { Empty: empty };
export default defaultExport;
