import { marked } from 'marked';
import { ContentStore } from '@stores';
import { DateTime } from 'luxon';
import { GroupDateMapper } from '../utils/GroupDateMapper';

export class InfoPanelViewCreator {
  constructor(
    private readonly contentStore: ContentStore = new ContentStore()
  ) {}

  public async createView(cohortStart: DateTime, cohortId: number) {
    const infoContent = await this.contentStore.readByContentType(
      'info-panel',
      (item) => {
        return {
          title: item.fields['title'],
          content: marked(item.fields['content']),
          offset: item.fields['offset'],
        };
      }
    );

    const dateMapper = new GroupDateMapper(cohortStart);
    const currentOffset = dateMapper.day(DateTime.local().toISODate());

    // We need to return an array of all the items that have an offset less than or equal to the current offset along with their dismissed status
    const infoItems = infoContent.filter(
      (item) => item.offset <= currentOffset
    );
    const infoWithDismissedStatus = infoItems.map((item) => {
      const dismissed = Boolean(
        localStorage.getItem(`dashboard:info:${item.title}|${cohortId}`)
      );
      return { ...item, dismissed };
    });
    return infoWithDismissedStatus;
  }
}
