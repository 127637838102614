// src/components/RoleBasedAccess.tsx
import React, { ReactNode, useContext } from 'react';
import { UserRoleContext } from './UserRoleProvider';
import { Role } from '../../enums/Role';

interface RoleBasedAccessProps {
  requiredRoles: Role[];
  children: ReactNode;
}

export const RoleBasedAccess: React.FC<RoleBasedAccessProps> = ({
  requiredRoles,
  children,
}) => {
  const userRole = useContext(UserRoleContext);

  // Check if user has the required role using bitwise AND
  let hasAccess = false;
  for (const neededRole of requiredRoles) {
    if ((userRole & neededRole) === neededRole) {
      hasAccess = true;
      break;
    }
  }

  return hasAccess ? <>{children}</> : null;
};
