import { ThemeOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface Theme {
    first: {
      main: string;
    };
    second: {
      main: string;
    };
    third: {
      main: string;
    };
  }

  interface ThemeOptions {
    first?: {
      main?: string;
    };
    second?: {
      main?: string;
    };
    third?: {
      main: string;
    };
  }

  interface TypographyVariants {
    cursive: React.CSSProperties;
    smallCursive: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    cursive?: React.CSSProperties;
    smallCursive?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cursive: true;
    smallCursive: true;
  }
}

const palette = {
  first: {
    main: '#2678e3',
  },
  second: {
    main: '#e3aa26',
  },
  third: {
    main: '#3ce326',
  },
  primary: {
    main: '#42797c',
    main50: 'rgba(66,121,124, .5)',
    light: '#679396',
    light50: 'rgb(103, 147, 150, .5)',
    dark: '#2E5456',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#879393',
    contrastText: '#fff',
    light: '#9FA8A8',
    dark: '#5E6666',
  },
  error: {
    main: '#ff5449',
    light: '#ff766d',
    dark: '#b23a33',
    contrastText: '#f7edea',
  },
  info: {
    main: '#d4ebfa',
    contrastText: '#f7edea',
    light: '#dceffb',
    dark: '#94a4af',
  },
  success: {
    main: '#197810',
    contrastText: '#f7edea',
    light: '#47933f',
    dark: '#11540b',
  },
  warning: {
    main: '#ed6c02',
    light: '#f08934',
    dark: '#a54b01',
    contrastText: '#f7edea',
  },
  background: {
    default: '#f4f4f4',
    paper: '#f4f4f4',
    border: 'rgba(0,0,0,.23)',
  },
  divider: '#1e1b1b',
};

const setHeadlineStyles = (typography: TypographyOptions) => {
  const headlineVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

  headlineVariants.forEach((variant) => {
    typography[variant] = {
      fontWeight: 'bold',
      color: '#42797c',
      fontSize: '1.5rem',
      ...typography[variant],
    };
  });

  return typography;
};

const themeOptions: ThemeOptions = {
  palette: palette,
  typography: setHeadlineStyles({
    fontFamily: 'Roboto',
    body1: { fontFamily: 'Roboto', color: '#050504' },
    body2: { fontFamily: '"Water Brush", cursive' },
    cursive: {
      fontFamily: '"Water Brush", cursive',
      fontSize: '40px',
      color: '#7F1319',
    },
    smallCursive: {
      fontFamily: '"Water Brush", cursive',
      fontSize: '28px',
      color: '#7F1319',
    },
  }),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '10px',
          lineHeight: '1.1rem',
        },
      },
    },
  },
};

export { palette, themeOptions };
