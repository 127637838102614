import { ApiService } from './ApiService';
import { DateTime } from 'luxon';

export type RawCohortUserSurvey = {
  sectionId: number;
  availableDate: DateTime;
  cohortUserId: number;
  surveyId: number;
};

export class CohortUserSurveyStore {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async InitializeUserOnboardingSurvey(
    cohortUserId: number
  ): Promise<void> {
    await this.api.post(`/CohortUser/${cohortUserId}/surveys/onboarding`, {});
  }
}
