import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ActivityHistoryView } from '@views';
import { DebugPanel } from '../DebugPanel';
import { createTheme } from '@mui/material/styles';

interface ExerciseHistoryProps {
  view: ActivityHistoryView;
  open: boolean;
  onClose: () => void;
}

export const ExerciseHistory: FC<ExerciseHistoryProps> = ({
  open,
  onClose,
  view,
}) => {
  const cols = [
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Exercise Name',
      width: 200,
    },
    {
      field: 'activity',
      headerName: 'Activity',
      width: 200,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 200,
    },
    {
      field: 'timeframe',
      headerName: 'Timeframe',
      width: 200,
    },
  ] as GridColDef[];

  const theme = React.useMemo(() => createTheme(), []);
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DebugPanel displayItem={view} />
      <DialogTitle>Exercise History</DialogTitle>
      <DialogContent>
        <DataGrid
          sx={{ fontFamily: theme.typography.fontFamily }}
          columns={cols}
          rows={view.items}
        ></DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
