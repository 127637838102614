import React, { Fragment } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { GroupDialogContent } from './GroupDialogContent';
import { HideableButton } from '../HideableButton';
import { GroupView } from '../../views/GroupView';
import { ParticipantEditor } from './ParticipantEditor';
import { ParticipantList } from './ParticipantList';
import { ParticipantView } from '../../views/ParticipantView';
import { DebugPanel } from '../DebugPanel';

export interface NewGroupModalProps extends React.PropsWithChildren {
  open: boolean;
  onClose: (e: any, reason: string) => void;
  group: GroupView;
  onGroupChange: (e: any) => void;
  cancelModal: () => void;
  onAddParticipant: (e: any) => void;
  onRemoveParticipant: React.MouseEventHandler<HTMLButtonElement>;
  onNextStep: React.MouseEventHandler<HTMLButtonElement>;
  onPrevStep: React.MouseEventHandler<HTMLButtonElement>;
  activeStep: number;
  onRoleChanged: (e: any, participant: ParticipantView) => void;
}

export const NewGroupModal: React.FC<NewGroupModalProps> = ({
  onAddParticipant,
  onRemoveParticipant,
  cancelModal,
  onGroupChange,
  group,
  open,
  onClose,
  onNextStep,
  onPrevStep,
  activeStep,
  onRoleChanged,
}) => {
  const handleClose = (e: any, reason: string) => {
    onClose(e, reason);
  };

  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth={true}
      slotProps={{
        backdrop: { style: { backgroundColor: 'background.paper' } },
      }}
      open={open}
      onClose={(e) => handleClose(e, 'backdropClicked')}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DebugPanel displayItem={group} />
      <DialogTitle>New Cohort Group</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Create Group</StepLabel>
          </Step>
          <Step>
            <StepLabel>Invite Participants</StepLabel>
          </Step>
          <Step>
            <StepLabel>Finish</StepLabel>
          </Step>
        </Stepper>
      </DialogContent>

      {(activeStep === 0 || activeStep === 2) && (
        <Fragment>
          <GroupDialogContent group={group} onGroupChange={onGroupChange}>
            <ParticipantList
              length={group.participants.length}
              participants={group.participants}
            ></ParticipantList>
          </GroupDialogContent>
        </Fragment>
      )}
      {activeStep === 1 && (
        <ParticipantEditor
          onRoleChange={onRoleChanged}
          userCount={group.userCount}
          participantViews={group.participants}
          onAddParticipant={onAddParticipant}
          onRemoveParticipant={onRemoveParticipant}
        />
      )}

      <DialogActions>
        <Button
          onClick={cancelModal}
          variant={'contained'}
          disableElevation={true}
          color={'secondary'}
          size={'large'}
        >
          Cancel
        </Button>

        <HideableButton
          visible={activeStep > 0}
          onClick={onPrevStep}
          variant={'contained'}
          size={'large'}
        >
          &lt; Back
        </HideableButton>

        <HideableButton
          visible={activeStep < 2}
          onClick={onNextStep}
          variant={'contained'}
          size={'large'}
        >
          Next &gt;
        </HideableButton>

        <HideableButton
          visible={activeStep === 2}
          onClick={(e) => handleClose(e, 'SAVE')}
          variant={'contained'}
          size={'large'}
        >
          Save
        </HideableButton>
      </DialogActions>
    </Dialog>
  );
};
