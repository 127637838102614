import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './pages/Dashboard/Dashboard.slice';
import groupsReducer from './pages/Groups/Groups.slice';
import authReducer from './reducers/Auth.slice';
import messageReducer from './components/MessageThread/MessageThread.slice';
import tokenReducer from './reducers/TokenReducer';

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    groups: groupsReducer,
    auth: authReducer,
    messages: messageReducer,
    token: tokenReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
