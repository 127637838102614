export type ApiResponse<T = any> = {
  status: 'SUCCESS' | 'ERROR';
  statusCode: number;
  statusMessage: string;
  error: Error | null | unknown;
  result: T | null;
};

export type BasicFetchResponse<T = any> = {
  status: number;
  statusText: string;
  data: T;
};

function isBasicFetchResponse<T>(err: unknown): err is BasicFetchResponse<T> {
  return (
    typeof err === 'object' &&
    err !== null &&
    'status' in err &&
    typeof (err as BasicFetchResponse<T>).status === 'number' &&
    'statusText' in err &&
    typeof (err as BasicFetchResponse<T>).statusText === 'string' &&
    'data' in err
  );
}

const successResponse = <T>(
  response: BasicFetchResponse<T>
): ApiResponse<T> => {
  return {
    status: 'SUCCESS',
    statusCode: response.status,
    statusMessage: response.statusText,
    error: null,
    result: response.data as T,
  };
};

const errorResponse = (
  response: BasicFetchResponse,
  errMsg: any
): ApiResponse => {
  return {
    status: 'ERROR',
    statusCode: response.status,
    statusMessage: errMsg,
    error: new Error(errMsg),
    result: null,
  };
};

const handleError = (err: unknown): ApiResponse => {
  let message, error;
  if (err instanceof Error) {
    error = err as Error;
    console.error(error);
    return errorResponse(
      {
        status: 500,
        statusText: 'Internal Server Error',
      } as BasicFetchResponse,
      error.message
    );
  }
  if (isBasicFetchResponse(err)) {
    return errorResponse(err as BasicFetchResponse, err.statusText);
  } else {
    message = String(err);
  }
  console.error(message);
  return errorResponse(
    { status: 500, statusText: 'Internal Server Error' } as BasicFetchResponse,
    message
  );
};

export const handleApiResponse = async <T>(
  apiCall: () => Promise<ApiResponse<T>>
): Promise<T> => {
  const response = await apiCall();
  if (response.status === 'ERROR') {
    console.error(response.statusMessage);
    throw new Error(response.statusMessage);
  }
  return response.result as T;
};

const defaultExport = {
  Success: successResponse,
  Error: errorResponse,
  HandleError: handleError,
  handleApiResponse: handleApiResponse,
};

export default defaultExport;
