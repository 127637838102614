import { ViewCreator } from './ViewCreator';
import dashboardView, {
  DashboardView,
  QuestionView,
  ResponseView,
} from '../views/DashboardView';
import { RoleStrings } from '../enums/Role';
import { Group } from '../models';
import { GroupStore, TaskStore } from '@stores';
import { DateTime } from 'luxon';
import participantView, { ParticipantView } from '../views/ParticipantView';
import { Question } from '../models/Question';
import { Response } from '../models/Response';
import { LeaderView } from '@views';
import { ToDateTime } from '../utils/DateUtils';

enum OnboardingSections {
  GoalSetting = 0,
  Pledge = 1,
  AccountabilityPartner = 2,
}

interface DashboardViewArgs {
  groupId: number;
  userId: string;
}

export class DashboardViewCreator extends ViewCreator<
  Promise<DashboardView>,
  DashboardViewArgs
> {
  createView: () => Promise<DashboardView>;
  createViewWithArg: (arg: DashboardViewArgs) => Promise<DashboardView>;

  constructor(
    private readonly store: TaskStore = new TaskStore(),
    private readonly groupStore: GroupStore = new GroupStore()
  ) {
    super();

    this.createView = async (): Promise<DashboardView> => {
      throw new Error('Method not implemented');
    };

    this.createViewWithArg = async ({
      groupId,
      userId,
    }: DashboardViewArgs): Promise<DashboardView> => {
      const [taskResponse, groupResponse] = await Promise.all([
        await this.store.ReadGroupTasks(userId, groupId),
        await this.groupStore.ReadOne(groupId),
      ]);

      if (taskResponse.status === 'ERROR') {
        console.error(`Error: ${taskResponse.statusMessage}`);
        throw new Error(taskResponse.statusMessage);
      }

      if (groupResponse.status === 'ERROR') {
        console.error(`Error: ${groupResponse.statusMessage}`);
        throw new Error(groupResponse.statusMessage);
      }

      // const tasks = taskResponse.result || []
      const group = groupResponse.result as Group;

      const initialSection = group.sections[0] ?? dashboardView.Empty.section;
      let section = dashboardView.Empty.section;
      const start = ToDateTime(group.startDate);
      let needPayment =
        DateTime.now() >= start &&
        !group.pledgeReceived &&
        (initialSection?.id ?? 0) === OnboardingSections.AccountabilityPartner;

      if (
        !group
          .cohortUsers!.find((p) => p.id === group.cohortUserId)!
          .role.split('|')
          .some((x) => x === 'Participant')
      ) {
        needPayment = false;
      }

      if (initialSection && !needPayment) {
        section = {
          id: initialSection.id,
          description: '',
          title: initialSection.name,
          questions: initialSection.questions.map(
            (question: Question) =>
              ({
                name: question.name,
                id: question.id,
                text: question.text,
                userResponse: '',
                responses: question.responses.map(
                  (response: Response) =>
                    ({
                      text: response.text,
                      id: response.id,
                      responseType: response.responseType,
                      selected: false,
                      textResponse: '',
                    }) as ResponseView
                ),
              }) as QuestionView
          ),
        };
      }

      const leaderView: LeaderView = Object.keys(group.leaderboard)
        .map((rank) => {
          const leader = group.leaderboard[Number(rank)];
          const cohortUser = group.cohortUsers?.find(
            (u) => u.userId === leader.participant.userId
          );
          const user = cohortUser?.user;
          const participant = {
            name: user?.name,
            role: cohortUser?.role,
            avatarUrl: user?.avatarUrl,
            email: user?.email,
            id: user?.id,
            CohortUser: { id: cohortUser?.id },
          } as ParticipantView;
          return {
            [Number(rank)]: {
              participant: participant,
              successfulWeeks: leader.successfulWeeks,
              currentStreak: leader.currentStreak,
            },
          };
        })
        .reduce((view, item) => {
          const rank = Number(Object.keys(item)[0]);
          view[rank] = item[rank];
          return view;
        }, {} as LeaderView);

      return {
        groupStartDate: group.startDate,
        potAmount: group.potAmount,
        pledgeAmount: group.pledgeAmount,
        leaders: leaderView,
        cohortUserId: group.cohortUserId,
        logo: group.groupLogo,
        groupChatId: group.chat?.sid ?? '',
        groupName: group.name,
        groupId: groupId,
        tasks: [], //[...tasks] as TaskView[],
        progress: group.goalProgress,
        needPayment: needPayment,
        availableJailCards: group.availableJailCards,
        team: {
          participantCount: group.cohortUsers?.length || 0,
          participants: group.cohortUsers
            ?.filter((u) => u.userId !== Number(userId))
            .map((u) => {
              return {
                ...u.user,
                rank: 0,
                isNew: false,
                score: 0,
                role: RoleStrings.Participant,
                CohortUser: { id: u.id },
              } as ParticipantView;
            }) ?? [participantView.Empty],
        },
        section: section,
      };
    };
  }
}
