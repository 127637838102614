import { ApiService } from './ApiService';
import apiResponse, { ApiResponse } from './ApiResponse';

export class UserStore {
  constructor(private readonly api: ApiService = new ApiService()) {}

  public async getUserRole(email: string): Promise<ApiResponse<string>> {
    try {
      const response = await this.api.get(`/user/role/${email}`);
      return apiResponse.Success<string>(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }

  public async MergeUsers(
    cohortId: number,
    user: { id: number; email: string; role: string }
  ) {
    try {
      const response = await this.api.post('/cohortuser/associate', {
        cohortId: cohortId,
        raw: user,
      });
      return apiResponse.Success(response);
    } catch (e) {
      return apiResponse.HandleError(e);
    }
  }
}
