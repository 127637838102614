import React, { useCallback, useEffect, useState } from 'react';
import { AddActivityAction } from '../../actions/AddActivityAction';
import {
  GoalSummaryView,
  valueToActivityString,
  valueToString,
} from '../../views/DashboardView';
import { DateTime } from 'luxon';
import { RecordActivityView } from '../../views/RecordActivityView';
import { GoalView } from '../../views/GoalView';
import { RawGoal } from '../../views/RawGoal';
import { ValidateGoalFormAction } from '../../actions/ValidateGoalFormAction';
import { Alert, AlertTitle } from '@mui/material';
import { common } from '@mui/material/colors';
import { themeOptions } from '../../palette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export type GoalCompletionStatus = {
  [goalId: number]: number[]; // or [goalId: number]: number[];
};
export type GoalsWithStatus = {
  goals: GoalSummaryView[];
  progress: GoalCompletionStatus;
};

export const useGoalsTrackerCardState = (
  goals: GoalsWithStatus,
  cohortUserId: number,
  refreshActivities: () => void
) => {
  const initialView: RecordActivityView = {
    duration: 0,
    name: '',
    activityId: 2,
    cohortUserId: cohortUserId,
    timeframeId: 1,
    dateStamp: DateTime.now(),
  };

  const defaultView = {
    name: '',
    activityId: -1,
    duration: 0,
    durationTimeframe: 2,
    frequency: 1,
    timeframe: 1,
    startDate: DateTime.local().startOf('week'),
    endDate: DateTime.local()
      .startOf('week')
      .plus({ weeks: 4 })
      .minus({ days: 1 }),
    datesEnabled: true,
    alternate: '',
  };

  const [view, setView] = useState<GoalView>(defaultView);

  const [confirmGoalDialogOpen, setConfirmGoalDialogOpen] =
    useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | null>
  >({});
  const [jailCardOpen, setJailCardOpen] = useState<boolean>(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState<boolean>(false);
  const [activityView, setActivityView] =
    useState<RecordActivityView>(initialView);
  const [activityTrackingDialogOpen, setActivityTrackingDialogOpen] =
    useState<boolean>(false);
  const [goalCompletionStatus, setGoalCompletionStatus] =
    useState<GoalCompletionStatus>({});
  const [selectedGoal, setSelectedGoal] = useState<GoalSummaryView>();
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    view.name = `I commit to ${valueToActivityString(view.activityId)} ${view.frequency} ${valueToString(view.timeframe)} for ${view.duration} ${valueToString(view.durationTimeframe)}`;
    setView({ ...view });
  }, [
    view.activityId,
    view.duration,
    view.durationTimeframe,
    view.frequency,
    view.timeframe,
  ]);
  useEffect(() => {
    setGoalCompletionStatus(goals.progress);
  }, [goals.progress]);

  const [rawGoals, setRawGoals] = useState<RawGoal[]>([]);
  const bodyFont =
    (themeOptions.typography as TypographyOptions).body1!.fontFamily ||
    'roboto';

  function addRawGoal() {
    rawGoals.push({
      name: view.name,
      cohortUserId: cohortUserId,
      activityId: view.activityId,
      duration: view.duration,
      durationTimeframeId: view.durationTimeframe,
      frequency: view.frequency,
      timeframeId: view.timeframe,
      endDate: view.endDate,
      startDate: view.startDate,
    });
    setRawGoals([...rawGoals]);
  }

  const onAddGoal = useCallback(() => {
    addRawGoal();
    setView({ ...defaultView });
  }, [cohortUserId, goals, view, defaultView]);

  const onRemoveGoal = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, index: number) => {
      rawGoals.splice(index, 1);
      rawGoals.splice(index, 1);
      setRawGoals([...rawGoals]);
    },
    []
  );

  const handleChange = async (fieldName: string, value: any) => {
    const newView = { ...view, [fieldName]: value };
    setView(newView);

    // Trigger real-time validation
    const { errors } = await ValidateGoalFormAction.Execute(
      cohortUserId,
      newView,
      { validateField: fieldName }
    );
    setValidationErrors((prev) => ({
      ...prev,
      [fieldName]: errors[fieldName],
    }));
  };

  const handleActivityChange = (e: {
    target: { name: string; value: any };
  }) => {
    const { name, value } = e.target;
    setActivityView({
      ...activityView,
      cohortUserId: cohortUserId,
      [name]: value,
    });
  };

  const addActivity = async () => {
    await new AddActivityAction().Execute(activityView);
    setActivityTrackingDialogOpen(false);
  };

  const handleToggle = (goal: GoalSummaryView, index: number) => {
    setConfirmGoalDialogOpen(true);
    setSelectedGoal(goal);
    const goalId = goal.id;

    setGoalCompletionStatus((prev) => {
      const currentStatus = prev[goalId] || Array(goal.frequency).fill(0); // default to an array of 0s

      const newStatus = currentStatus.map((status, i) => {
        return i === index ? (status === 1 ? 0 : 1) : status; // toggle between 1 and 0
      });

      return {
        ...prev,
        [goalId]: newStatus,
      };
    });
  };

  const trackActivity = async () => {
    const item = {
      activityId: selectedGoal?.activity,
      cohortUserId: cohortUserId,
      dateStamp: DateTime.now(),
      duration: selectedGoal?.duration,
      timeframeId: selectedGoal?.timeframe,
      name: selectedGoal?.goalText,
    } as RecordActivityView;
    await new AddActivityAction().Execute(item);
    setConfirmGoalDialogOpen(false);
    refreshActivities();
  };

  const snackAlertAction = (
    <Alert
      variant={'filled'}
      sx={{
        width: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
      onClose={() => {
        setAlertOpen(false);
      }}
    >
      <AlertTitle color={common.white}>Success!</AlertTitle>
      <p style={{ color: common.white, fontFamily: bodyFont }}>WAY TO GO!</p>;
    </Alert>
  );
  return {
    handleActivityChange,
    activityView,
    activityTrackingDialogOpen,
    setActivityTrackingDialogOpen,
    addActivity,
    handleToggle,
    historyDialogOpen,
    setHistoryDialogOpen,
    jailCardOpen,
    setJailCardOpen,
    confirmGoalDialogOpen,
    alertOpen,
    setAlertOpen,
    goalCompletionStatus,
    selectedGoal,
    setConfirmGoalDialogOpen,
    setGoalCompletionStatus,
    trackActivity,
    validationErrors,
    view,
    snackAlertAction,
    onAddGoal,
    onRemoveGoal,
    rawGoals,
    addRawGoal,
    handleChange,
  };
};
