import { palette } from '../palette';

export interface ProgressWeekView {
  id: number;
  label: string;
  name: string;
  value: number;
  color: string;
  goal: boolean;
}

const empty: ProgressWeekView = {
  id: 0,
  label: '',
  name: '',
  value: 0,
  color: palette.secondary.light,
  goal: false,
};

const defaultExport = { Empty: empty };
export default defaultExport;
