import { GroupView } from '../../views/GroupView';
import { UserStore } from '../../api/UserStore';
import actionResponse, { ActionResponse } from '../ActionResponse';
import * as uuid from 'uuid';
import { Client } from '@twilio/conversations';
import apiResponse from '../../api/ApiResponse';
import { GroupStore } from '../../api/GroupStore';
import { ChatStore } from '../../api/ChatStore';
import { CohortUserSurveyStore } from '../../api/CohortUserSurveyStore';
import { TwilioUserService } from '../../api/TwilioUser';

export class CreateGroupAction {
  constructor(
    private readonly store: GroupStore = new GroupStore(),
    private readonly userStore: UserStore = new UserStore(),
    private readonly chatStore: ChatStore = new ChatStore(),
    private readonly surveyStore: CohortUserSurveyStore = new CohortUserSurveyStore(),
    private readonly twilioUserService: TwilioUserService = new TwilioUserService()
  ) {}

  async Execute(
    reason: 'SAVE' | string,
    selectedGroup: GroupView,
    client: Client
  ): Promise<ActionResponse> {
    if (reason && reason === 'SAVE') {
      if (client && client.connectionState === 'connected') {
        const convo = await client.createConversation({
          friendlyName: selectedGroup.name,
          uniqueName: `${selectedGroup.name}_${uuid.v4()}`,
        });
        if (convo) {
          selectedGroup.chatSid = convo.sid;

          for (const participant of selectedGroup.participants) {
            await this.twilioUserService.Write(participant.email);
            await convo.add(participant.email);
          }
        }

        await apiResponse.handleApiResponse(() =>
          this.store.Write(selectedGroup)
        );
        return actionResponse.Success('CreateGroupAction.execute done');
      } else {
        return actionResponse.Error('ERROR connecting to  Twilio');
      }
    }
    return actionResponse.Success('CreateGroupAction.execute done');
  }
}
