import { DateTime } from 'luxon';
import { ToDate } from './DateUtils';

export class GroupDateMapper {
  dayToDate: Record<number, string> = {};
  dateToDay: Record<string, number> = {};

  constructor(
    private readonly startDate: DateTime<true>,
    private readonly weeks: number = 13
  ) {
    const daysInWeek = 7;
    const totalDays = weeks * daysInWeek;
    for (let day = 0; day <= totalDays; day++) {
      const start = ToDate(startDate);

      const formattedDate = start.plus({ day: day }).toISODate() as string;
      this.dayToDate[day] = formattedDate;
      this.dateToDay[formattedDate] = day;
    }
  }

  public day = (date: string) => {
    return this.dateToDay[date] + 1;
  };

  public date = (day: number) => {
    return this.dayToDate[day - 1];
  };
}
