import { GroupView } from '../../views/GroupView';
import { DateTime } from 'luxon';
import actionResponse, { ActionResponse } from '../ActionResponse';

export default class GroupSelectedAction {
  public Execute = async (
    selectedGroup: GroupView,
    fieldName: string,
    setSelectedGroup: (
      value: ((prevState: GroupView) => GroupView) | GroupView
    ) => void,
    setShowEditGroupModal: (
      value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    resetState: () => void
  ): Promise<ActionResponse> => {
    try {
      if (fieldName === 'ACTIONS') {
        // Clicked the actions Trashcan button
        resetState();
        return actionResponse.Success('Actions buttons selected'); //Do Nothing, let the button click event handle it
      }
      // Need to show the group modal with all the fields
      if (typeof selectedGroup.startDate === 'string') {
        selectedGroup.startDate = DateTime.fromISO(selectedGroup.startDate);
      }
      setSelectedGroup(selectedGroup);
      setShowEditGroupModal(true);

      return actionResponse.Success('Group selected successfully');
    } catch (error) {
      return actionResponse.HandleError(error);
    }
  };
}
