import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { DebugPanel } from '../DebugPanel';
import { Lookup } from '../../views/Lookup';
import { ActivityLookupViewCreator } from '../../view-creator/ActivityLookupViewCreator';

import { RecordActivityView } from '../../views/RecordActivityView';

interface RecordExerciseProps {
  view: RecordActivityView;
  open: boolean;
  onChange: (e: { target: { name: string; value: any } }) => void;
  onSave: (e: any) => void;
  onClose: (e: any) => void;
}

export const RecordExercise: FC<RecordExerciseProps> = ({
  onClose,
  onSave,
  onChange,
  open,
  view,
}) => {
  const [activities, setActivities] = useState<Lookup[]>([] as Lookup[]);
  const [timeframes, setTimeframes] = useState<Lookup[]>([] as Lookup[]);
  useEffect(() => {
    new ActivityLookupViewCreator().createView().then((view) => {
      setActivities(view.activities);
      setTimeframes(view.timeframes);
    });
  }, []);
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DebugPanel displayItem={view} />
      <DialogTitle>Record Exercise</DialogTitle>
      <DialogContent>
        <Grid display={'flex'} container spacing={1} margin={1}>
          <Grid item xs={11}>
            <FormControl fullWidth>
              <TextField
                onChange={onChange}
                value={view.name}
                placeholder={'Name your activity'}
                name={'name'}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="activity">Activity</InputLabel>
              <Select
                label={'Activity'}
                name={'activityId'}
                size={'medium'}
                variant={'outlined'}
                value={view.activityId}
                onChange={onChange}
                labelId={'activity'}
              >
                <MenuItem value={-1} key={'default'}>
                  Select an Activity
                </MenuItem>
                {activities.map((a) => {
                  const key = `activity-${a.id}`;
                  return (
                    <MenuItem value={a.id} key={key}>
                      {a.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                onChange={onChange}
                value={view.duration}
                name={'duration'}
                placeholder={'Duration'}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="timeframe">Timeframe</InputLabel>
              <Select
                onChange={onChange}
                label={'Timeframe'}
                name={'timeframeId'}
                size={'medium'}
                value={view.timeframeId}
                variant={'outlined'}
                labelId={'timeframe'}
              >
                <MenuItem value={-1} key={'timeframe-default'}>
                  Select a Timeframe
                </MenuItem>
                {timeframes.map((t) => {
                  return (
                    <MenuItem value={t.id} key={`timeframe-${t.id}`}>
                      {t.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant={'contained'} onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
