// src/enums/Role.ts

export enum Role {
  Guest = 1 << 0, // 0000 00001
  Participant = 1 << 1, // 0000 0010
  Coach = 1 << 2, // 0000 0100
  GroupAdmin = 1 << 3, // 0000 1000
  Admin = 1 << 4, // 0001 0000
}

// Mapping between enum values and string representations
export enum RoleStrings {
  Admin = 'Admin',
  GroupAdmin = 'GroupAdmin', //ToDo: Make this value Captain. Admins are employees of WHE
  Participant = 'Participant',
  Guest = 'Guest',
  Coach = 'Coach',
}

// Reverse mapping for string to enum conversion
export const RoleValues: {
  [
    key:
      | string
      | RoleStrings.Admin
      | RoleStrings.GroupAdmin
      | RoleStrings.Participant
      | RoleStrings.Guest
      | RoleStrings.Coach
  ]: Role;
} = {
  Admin: Role.Admin,
  GroupAdmin: Role.GroupAdmin,
  Participant: Role.Participant,
  Guest: Role.Guest,
  Coach: Role.Coach,
};
