import { DebugPanel } from '../DebugPanel';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import logger from '../../utils/logger';
import React, { FC } from 'react';
import { GoalSummaryView } from '../../views/DashboardView';
import { GoalCompletionStatus, GoalsWithStatus } from './useGoalTrackerState';

interface ConfirmGoalCompletionDialogProps {
  open: boolean;
  onClose: () => void;
  selectedGoal: GoalSummaryView | undefined;
  setConfirmGoalDialogOpen: (dialogOpen: boolean) => void;
  setGoalCompletionStatus: (goalCompletionStatus: GoalCompletionStatus) => void;
  goals: GoalsWithStatus;
  trackActivity: () => Promise<void>;
  setAlertOpen: (refresh: boolean) => void;
}

export const ConfirmGoalCompletionDialog: FC<
  ConfirmGoalCompletionDialogProps
> = ({
  open,
  onClose,
  selectedGoal,
  setConfirmGoalDialogOpen,
  setGoalCompletionStatus,
  goals,
  trackActivity,
  setAlertOpen,
}) => {
  return (
    <Dialog open={open}>
      <DebugPanel displayItem={selectedGoal}></DebugPanel>
      <DialogTitle>Confirm Goal</DialogTitle>
      <DialogContent>
        <Typography>
          Did you complete a workout towards this part of your goal?
        </Typography>
        <Typography>{selectedGoal?.goalText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setConfirmGoalDialogOpen(false);
            setGoalCompletionStatus(goals.progress);
          }}
        >
          Cancel
        </Button>
        <Button
          variant={'contained'}
          onClick={() => {
            trackActivity()
              .then(() => {
                setAlertOpen(true);
              })
              .catch((err) => {
                logger.error(err);
              });
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
