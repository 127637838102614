import { Group } from '../models';
import { GroupView } from '../views/GroupView';
import { createParticipantView } from './CreateParticipantView';
import { GroupStore } from '../api/GroupStore';

export class GroupViewCreator {
  constructor(private readonly store: GroupStore = new GroupStore()) {}

  public async CreateView(group: Group): Promise<GroupView> {
    // const group = await apiResponse.handleApiResponse(() => this.store.ReadOne(g.id))
    return {
      id: group.id,
      pledge: group.pledge,
      startDate: group.startDate,
      chatSid: group.chat?.sid ?? '',
      name: group.name,
      cohortUserId: group.cohortUserId,
      userCount: group.userCount,
      groupLogo: group.groupLogo,
      shortDescription: group.shortDescription,
      participants: group.cohortUsers?.map((u) =>
        createParticipantView(u.user, u)
      ),
    } as GroupView;
  }
}
