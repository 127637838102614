import { Group } from '../../models';
import { GroupStore } from '../../api/GroupStore';
import actionResponse, { ActionResponse } from '../ActionResponse';
import { GroupView } from '../../views/GroupView';
import apiResponse from '../../api/ApiResponse';

export class UpdateGroupAction {
  constructor(private readonly store: GroupStore = new GroupStore()) {}

  public async Execute(
    raw: GroupView,
    reason?: 'SAVE' | string
  ): Promise<ActionResponse> {
    try {
      if (reason && reason === 'SAVE') {
        await apiResponse.handleApiResponse(() =>
          this.store.Update({
            ...raw,
            potAmount: 0,
            pledgeAmount: 0,
            leaderboard: {},
            sections: [],
            availableJailCards: [],
            goalProgress: {},
          } as Group)
        );
      }
      return actionResponse.Success('reason was not save, no action taken');
    } catch (err) {
      return actionResponse.HandleError(err);
    }
  }
}
