import React, { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Grid2 as Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { palette } from '../palette';
import { MessageView } from '@views';
import { useApp } from 'App.hook';
import './ChatConversation.scss';

interface ChatConversationProps {
  view: MessageView[];
  onSend: (e: any) => void;
  newMsg: string;
  onMessageChange: (e: any) => void;
  title?: string;
  autoScroll?: boolean;
  unreadMessages: number;
  cohortParticipants: any;
}

export const ChatConversation: FC<ChatConversationProps> = ({
  autoScroll = true,
  title,
  newMsg,
  view,
  onSend,
  onMessageChange,
  unreadMessages,
  cohortParticipants,
}) => {
  const messagesEndRef = useRef<any>();
  const { cookies } = useApp();

  // Automatically scroll to the bottom when new messages arrive
  useEffect(() => {
    if (messagesEndRef.current)
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [view]);

  // Group messages by date
  const groupMessagesByDate = (messages: MessageView[]) => {
    return messages.reduce((groups: Record<string, MessageView[]>, message) => {
      const formattedDate = message.date.toFormat('MMM dd yyyy');
      if (!groups[formattedDate]) {
        groups[formattedDate] = [];
      }
      groups[formattedDate].push(message);
      return groups;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(view);

  // Lookup map to provide avatar urls/names for each email
  const cohortParticipantMap = useMemo(() => {
    return cohortParticipants.reduce(
      (
        map: Record<string, { avatarUrl: string; name: string }>,
        participant: any
      ) => {
        map[participant.email] = {
          avatarUrl: participant.avatarUrl || '',
          name: participant.name || 'Unknown',
        };
        return map;
      },
      {
        'admin@wehateexercise.com': {
          avatarUrl: '',
          name: 'Admin',
        },
      }
    );
  }, [cohortParticipants]);

  return (
    <Fragment>
      <Badge
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        badgeContent={unreadMessages}
        color="primary"
      >
        <Typography variant="h5" component="div" color="primary">
          {title}
        </Typography>
      </Badge>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <Fragment key={date}>
            {/* Render the date label */}
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
              <Typography
                variant={'caption'}
                sx={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                {date}
              </Typography>
            </Box>
            {messages.map((m, idx) => (
              <Fragment key={`${idx}-${m.id}`}>
                <Box
                  flex={'1 1 100%'}
                  display={'flex'}
                  flexDirection={m.myMessage ? 'row-reverse' : 'row'}
                  gap={1}
                  alignItems={'center'}
                  justifyContent={m.myMessage ? 'end' : 'start'}
                >
                  <Tooltip title={m.author}>
                    <>
                      <Avatar
                        src={
                          m.myMessage
                            ? cookies.profile.picture
                            : cohortParticipantMap[m.author].avatarUrl
                        }
                        variant={'circular'}
                        sx={{
                          backgroundColor: m.myMessage
                            ? palette.primary.main
                            : palette.primary.main50,
                          height: 32,
                          width: 32,
                        }}
                      >
                        {m.author.toUpperCase()[0]}
                      </Avatar>
                    </>
                  </Tooltip>
                  <Box
                    borderRadius={8}
                    width={'70%'}
                    padding={2}
                    sx={{
                      backgroundColor: m.myMessage
                        ? palette.primary.main
                        : palette.primary.main50,
                    }}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <Typography
                      color={palette.primary.contrastText}
                      variant={'body1'}
                    >
                      {m.text}
                    </Typography>
                    {!m.myMessage && (
                      <p className="author-name">
                        {cohortParticipantMap[m.author].name}
                      </p>
                    )}
                  </Box>
                </Box>
                {autoScroll && messages.length === idx + 1 && (
                  <div ref={messagesEndRef}></div>
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </Box>
      <Grid
        sx={{ marginTop: 2 }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={2}
      >
        <TextField
          multiline={true}
          sx={{ width: '100%' }}
          label={'Reply'}
          value={newMsg}
          onChange={onMessageChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              // If the user presses enter (not shift+enter for a new line), send the message
              e.preventDefault(); // Prevent a new line from being added
              onSend(e);
            }
          }}
        ></TextField>
        <IconButton color={'primary'} onClick={onSend} value={newMsg}>
          <Send />
        </IconButton>
      </Grid>
    </Fragment>
  );
};
