import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  List,
  ListItem,
  Skeleton,
  Snackbar,
  ToggleButton,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { ExerciseHistory } from '../modals/ExerciseHistory';
import { ActivityHistoryView } from '../../views/ActivityHistoryView';
import { GoalSummaryView } from '../../views/DashboardView';
import { RecordExercise } from '../modals/RecordExercise';
import { Circle, CircleOutlined } from '@mui/icons-material';
import { DebugPanel } from '../DebugPanel';
import { useUiState } from '../../utils/UiStateHook';
import { RawGoal } from '../../views/RawGoal';
import {
  GoalCompletionStatus,
  useGoalsTrackerCardState,
} from './useGoalTrackerState';
import { ConfirmGoalCompletionDialog } from './ConfirmGoalCompletionDialog';
import { UpdateGoalsDialog } from './UpdateGoalsDialog';
import { ToDate } from 'utils/DateUtils';
import { DateTime } from 'luxon';
import DisabledCardState from 'components/Cards/DisabledCardState';
import { GoalCardViewCreator } from 'view-creator/GoalCardViewCreator';

interface GoalTrackerCardProps {
  cohortUserId: number;
  isLoading: boolean;
  activityHistoryView: ActivityHistoryView;
  enabled?: boolean;
  disabledMessage: string;
  refreshActivities: () => void;
  onUpdateGoal: (e: any) => void;
  week1: boolean;
  onModalClose: (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => void;
  showNewGoalDialog: boolean;
  reloadGoals: boolean;
}

export const GoalTrackerCard: React.FC<GoalTrackerCardProps> = ({
  enabled = false,
  week1 = false,
  disabledMessage,
  refreshActivities,
  isLoading,
  activityHistoryView,
  cohortUserId,
  onUpdateGoal,
  showNewGoalDialog,
  onModalClose,
  reloadGoals,
}) => {
  const uiState = useUiState(enabled);
  const [newGoalEnabled, setNewGoalEnabled] = React.useState<boolean>(false);

  const [goals, setGoals] = React.useState<{
    goals: GoalSummaryView[];
    progress: GoalCompletionStatus;
  }>({
    goals: [],
    progress: {},
  });
  const [jailCards, setJailCards] = React.useState<number>(0);

  const {
    handleActivityChange,
    activityView,
    activityTrackingDialogOpen,
    setActivityTrackingDialogOpen,
    addActivity,
    handleToggle,
    historyDialogOpen,
    setHistoryDialogOpen,
    jailCardOpen,
    setJailCardOpen,
    confirmGoalDialogOpen,
    alertOpen,
    setAlertOpen,
    goalCompletionStatus,
    selectedGoal,
    setConfirmGoalDialogOpen,
    setGoalCompletionStatus,
    trackActivity,
    validationErrors,
    view,
    snackAlertAction,
    onAddGoal,
    onRemoveGoal,
    addRawGoal,
    rawGoals,
    handleChange,
  } = useGoalsTrackerCardState(goals, cohortUserId, refreshActivities);

  const loadGoalCardView = async () => {
    // Get goals from the API
    const result = await new GoalCardViewCreator().CreateView(cohortUserId);

    // Set the goals and progress in the state
    setGoals(result.goalsWithProgress);
    setJailCards(result.jailCards);
    return;
  };

  useEffect(() => {
    // Get goals when the component mounts
    loadGoalCardView();
  }, []);

  useEffect(() => {
    // Get updated goals when the dashboard tells us to reload
    if (reloadGoals) {
      loadGoalCardView();
    }
  }, [reloadGoals]);

  useEffect(() => {
    // If this is not week 1
    // And goals exists
    // And the user has no active goals
    // Then enable the new goal button
    setNewGoalEnabled(!week1 && goals && goals.goals.length === 0);
  }, [week1, goals]);

  return (
    <Fragment>
      <Card
        variant={'elevation'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid',
          borderColor: uiState.borderColor,
          borderRadius: 4,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            color={enabled ? 'primary' : 'textDisabled'}
          >
            My Exercise Goal
          </Typography>
          {!enabled && (
            <DisabledCardState
              disabledMessage={disabledMessage}
              src="./assets/svg/calendar.svg"
              alt="Get stoked!"
            />
          )}

          {isLoading && (
            <ul>
              <li>
                <Typography
                  color={uiState.color}
                  variant={'h6'}
                  sx={{ fontWeight: 300 }}
                >
                  <Skeleton width={'80%'}></Skeleton>
                </Typography>
              </li>
            </ul>
          )}
          {!isLoading && (
            <Grid container display={'flex'} flexDirection={'column'}>
              {week1 && enabled && (
                <Typography
                  color={'primary'}
                  sx={{
                    marginTop: '10%',
                    textAlign: 'center',
                  }}
                  variant="h5"
                  component="p"
                  gutterBottom
                >
                  Don&apos;t worry! We won&apos;t start tracking goals until
                  week 1.
                </Typography>
              )}
              {enabled &&
                goals.goals.map((x) => (
                  <Grid key={x.id} sx={{ marginTop: '10px' }}>
                    <Typography>{x.goalText}</Typography>

                    {[...Array(x.frequency)].map((_, i) =>
                      // Only render toggle buttons for goals that have started
                      ToDate(x.startDate) <= ToDate(DateTime.now()) ? (
                        <ToggleButton
                          disabled={!enabled}
                          color={'primary'}
                          onClick={() => handleToggle(x, i)}
                          selected={goalCompletionStatus[x.id]?.includes(i)}
                          key={i}
                          sx={{
                            border: 0,
                            borderRadius: 30,
                            padding: 0.25,
                            margin: 0.5,
                          }}
                          value={`${x.id}-${i}`}
                        >
                          {goalCompletionStatus[x.id]?.[i] === 1 ? (
                            <Circle color={uiState.buttonColor} />
                          ) : (
                            <CircleOutlined color={uiState.buttonColor} />
                          )}
                        </ToggleButton>
                      ) : null
                    )}
                  </Grid>
                ))}
            </Grid>
          )}
        </CardContent>
        {enabled && (
          <CardActions
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            <Button onClick={() => setJailCardOpen(true)}>
              Show Available Jail Cards
            </Button>
            <Button
              disabled={!enabled}
              variant={'contained'}
              onClick={() => {
                refreshActivities();
                setHistoryDialogOpen(true);
              }}
            >
              View Recent Exercise
            </Button>
            {newGoalEnabled && (
              <Button
                disabled={!newGoalEnabled}
                variant={'contained'}
                onClick={onUpdateGoal}
              >
                Update Goals
              </Button>
            )}
          </CardActions>
        )}
      </Card>
      <RecordExercise
        onChange={handleActivityChange}
        view={activityView}
        open={activityTrackingDialogOpen}
        onClose={() => {
          setActivityTrackingDialogOpen(false);
        }}
        onSave={addActivity}
      ></RecordExercise>
      <ExerciseHistory
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        view={activityHistoryView}
      ></ExerciseHistory>

      <Dialog open={jailCardOpen} onClose={() => setJailCardOpen(false)}>
        <DebugPanel displayItem={jailCards}></DebugPanel>
        <DialogTitle>Available Get Out Of Jail Cards</DialogTitle>
        <DialogContent>
          <Typography component={'div'}>
            {'Available Get Out of Jail Free Cards:'}
            <Typography
              sx={{ paddingLeft: 2 }}
              component={'span'}
              variant={'h4'}
            >
              {jailCards}
            </Typography>
          </Typography>
          <List>
            {Array.from({ length: jailCards }, (_, index) => (
              <ListItem key={index}>
                <img
                  src={
                    'https://images.ctfassets.net/ihnuhnmumsts/SvOPfL2qrjRB68cpu5e96/3ff57b3375721b425c6ce8eade8dfcbf/B34EE134-411A-4A51-98B2-D45705EAEA72.png?fm=webp&q=75'
                  }
                  alt={'Jail'}
                  width="200"
                ></img>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setJailCardOpen(false)}>Dismiss</Button>
        </DialogActions>
      </Dialog>

      <ConfirmGoalCompletionDialog
        open={confirmGoalDialogOpen}
        onClose={() => setConfirmGoalDialogOpen(false)}
        goals={goals}
        selectedGoal={selectedGoal}
        setConfirmGoalDialogOpen={setConfirmGoalDialogOpen}
        setGoalCompletionStatus={setGoalCompletionStatus}
        setAlertOpen={setAlertOpen}
        trackActivity={trackActivity}
      ></ConfirmGoalCompletionDialog>

      <UpdateGoalsDialog
        validationErrors={validationErrors}
        showNewGoalDialog={showNewGoalDialog}
        view={view}
        addRawGoal={addRawGoal}
        onAddGoal={onAddGoal}
        onRemoveGoal={onRemoveGoal}
        onModalClose={onModalClose}
        handleChange={handleChange}
        rawGoals={rawGoals}
      ></UpdateGoalsDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        {snackAlertAction}
      </Snackbar>
    </Fragment>
  );
};
