import { FC } from 'react';
import { Container } from '@mui/material';
import { GroupAdmin } from '../../components/Admin/GroupAdmin';

export const AdminHome: FC = () => {
  return (
    <>
      <Container component={'section'}>
        <GroupAdmin></GroupAdmin>
      </Container>
    </>
  );
};
