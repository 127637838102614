import { PropsWithChildren, useState } from 'react';
import {
  DialogContent,
  Grid2,
  ImageList,
  ImageListItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GroupView } from '../../views/GroupView';
import { CheckBox } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

interface Props extends PropsWithChildren {
  group: GroupView;
  onGroupChange: (e: any) => void;
}

export const GroupDialogContent: React.FC<Props> = ({
  children,
  group,
  onGroupChange,
}) => {
  const [roleNameError, setRoleNameError] = useState(false);
  const [roleNameHelperText, setRoleNameHelperText] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const validateRoleName = () => {
    if (group.name.length < 1) {
      setRoleNameError(true);
      setRoleNameHelperText('Role name is required');
    } else {
      setRoleNameError(false);
      setRoleNameHelperText('');
    }
  };
  const images = [
    'https://images.ctfassets.net/ihnuhnmumsts/1EasWh1Y9FSCdDXBcazZPZ/51c3d9adbb405ff53e801526639ffeb8/Snow_Background.jpg',
    'https://images.ctfassets.net/ihnuhnmumsts/6YidqNfJpvokPDnYmirDj2/cd64dcdb6e4817984df25614fe119300/Lights_Background.jpg',
    'https://images.ctfassets.net/ihnuhnmumsts/5WNnU8GQaXMzw4AKsLziE/2a9a995ccb5616deffe6b79793558ace/Untitled_design__8_.png',
    'https://images.ctfassets.net/ihnuhnmumsts/74LRyxs2QoRQtYAhi4Q0PL/b8c39c5ec56e20023fe2fc21087d961b/Sunset_Background.jpg',
    'https://images.ctfassets.net/ihnuhnmumsts/2jQQdl0Uc9tRZ8Eufz0Gfj/176b5409a67efc6234113a2a42d12a38/Ocean_Background.jpg',
    'https://images.ctfassets.net/ihnuhnmumsts/5QzrV1fBFkhyRuU04K8DS9/5f77300913fc83d9746c760fc0ac9b35/Tie_Dye_Background.jpg',
  ];
  return (
    <DialogContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        overflow: 'scroll',
      }}
    >
      <TextField
        label={'Group ID'}
        value={group.id}
        disabled={true}
        slotProps={{
          input: {
            readOnly: true,
            hidden: true,
          },
        }}
        fullWidth={true}
        rows={1}
      >
        {group.id}
      </TextField>

      <TextField
        required
        id={'name'}
        onChange={onGroupChange}
        value={group.name}
        label={'Group Name'}
        error={roleNameError}
        onKeyUp={validateRoleName}
        fullWidth={true}
        variant={'outlined'}
        helperText={roleNameHelperText}
      ></TextField>

      <DatePicker
        label={'Start Date'}
        key={'startDate'}
        value={group.startDate}
        onChange={onGroupChange}
      ></DatePicker>

      <TextField
        id={'shortDescription'}
        onChange={onGroupChange}
        value={group.shortDescription || ''}
        label={'Short Description'}
        multiline
        fullWidth={true}
        rows={4}
        variant={'outlined'}
        content={group.shortDescription}
      ></TextField>

      <TextField
        id={'userCount'}
        onChange={onGroupChange}
        value={group.userCount}
        label={'Expected Number of Participants'}
        type={'number'}
        fullWidth={true}
        rows={4}
        variant={'outlined'}
      ></TextField>

      <Grid2
        container
        display={'flex'}
        flexDirection={'row'}
        gap={1}
        alignSelf={'center'}
      >
        <Grid2 size={12}>
          <Typography>Group Logo</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 10, md: 10, lg: 8, xl: 8 }}>
          <ImageList
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            {images.map((src, idx) => {
              return (
                <ImageListItem
                  key={`img-${idx}`}
                  sx={{
                    height: 300,
                    width: 300,
                    boxShadow:
                      selectedImage === src
                        ? '0 4px 20px rgba(0, 0, 0, 0.7)'
                        : 'none', // Subtle shadow on selection
                    cursor: 'pointer',
                    transition: 'box-shadow 0.3s ease-in-out', // Smooth transition
                  }}
                  onClick={(e) => {
                    setSelectedImage(src);
                    group.groupLogo = src;
                    onGroupChange(e);
                  }}
                >
                  {selectedImage === src && (
                    <CheckBox
                      fontSize={'large'}
                      sx={{
                        background: grey[900],
                        border: '2px solid',
                        borderColor: grey[900],
                        margin: 0,
                        padding: 0,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        color: grey[100],
                      }}
                    />
                  )}

                  <img
                    src={src + '?fm=webp&q=1&w=300&h=300'}
                    alt={'background'}
                  ></img>
                </ImageListItem>
              );
            })}
          </ImageList>
        </Grid2>
      </Grid2>

      {children}
    </DialogContent>
  );
};
