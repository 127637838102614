import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGroupSurveyConfigurationModal } from './GroupSurveyConfigurationModal.hook';
import { GroupView } from '../../views/GroupView';
import { DateTime } from 'luxon';
import { DebugPanel } from '../DebugPanel';

interface GroupSurveyConfigurationProps extends PropsWithChildren {
  group: GroupView;
  open: boolean;
  onClose: () => void;
}

export const GroupSurveyConfigurationModal: React.FC<
  GroupSurveyConfigurationProps
> = ({ group, open, onClose }) => {
  const {
    surveys,
    selectedSurveyId,
    selectedSurvey,
    showSections,
    handleSaveSurveyConfiguration,
    onDateSelected,
  } = useGroupSurveyConfigurationModal();

  const handleSave = async () => {
    await handleSaveSurveyConfiguration(group);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DebugPanel displayItem={selectedSurvey} />
      <DialogTitle>{`Configure Surveys for ${group.name}`}</DialogTitle>
      <DialogContent>
        <Box padding={1} sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel key={'asdf'} id="available-surveys-label">
              Survey
            </InputLabel>
            <Select
              labelId="available-surveys-label"
              id="available-surveys"
              value={selectedSurveyId}
              label="Surveys"
              onChange={(e: SelectChangeEvent<number>) =>
                showSections(e, group.id)
              }
            >
              {surveys?.views.map((survey) => {
                return (
                  <MenuItem key={survey.id} value={survey.id}>
                    {survey.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography paddingLeft={2} paddingTop={2} variant={'h5'}>
            Sections
          </Typography>
          <List>
            {selectedSurvey?.sections.map((section) => {
              const referenceDate = DateTime.fromISO(String(group.startDate));
              const maxDate = referenceDate.plus({ day: 6 });
              return (
                <ListItem key={section.sectionId} sx={{ padding: 2 }}>
                  <ListItemText>{section.title}</ListItemText>
                  <ListItemSecondaryAction>
                    <DatePicker
                      onChange={(e) => onDateSelected(e, section)}
                      label={'Send Section On...'}
                      maxDate={maxDate}
                      disableHighlightToday
                      closeOnSelect
                      minDate={referenceDate}
                      value={section.availableDate}
                      referenceDate={referenceDate}
                    ></DatePicker>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={'contained'} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
