import { grey } from '@mui/material/colors';
import { palette } from '../palette';

export type UiState = {
  backgroundColor: string | undefined;
  color: string | undefined;
  borderColor: string | undefined;
  buttonColor: 'disabled' | 'primary';
  backgroundFilter: string | undefined;
  backdropFilter: string | undefined;
};
export const useUiState = (enabled: boolean): UiState => {
  return {
    backgroundColor: enabled ? undefined : grey[500],
    color: enabled ? 'primary' : 'textDisabled',
    borderColor: enabled ? palette.primary.main : grey[500],
    buttonColor: enabled ? 'primary' : 'disabled',
    backgroundFilter: enabled ? undefined : 'grayscale(100%)',
    backdropFilter: enabled ? undefined : 'blur(20px)',
  };
};
