import { useEffect, useState } from 'react';
import { Survey } from '../../models/Survey';
import { GridColDef } from '@mui/x-data-grid';
import { AddOptionAction } from '../../actions/Survey/AddOptionAction';
import { AddQuestionAction } from '../../actions/Survey/AddQuestionAction';
import { OptionChangedAction } from '../../actions/Survey/OptionChangedAction';
import { RemoveOptionAction } from '../../actions/Survey/RemoveOptionAction';
import { SaveSurveyAction } from '../../actions/SaveSurveyAction';
import {
  SurveySummaryView,
  SurveySummaryViewCreator,
} from '../../view-creator/SurveySummaryViewCreator';
import { UpdateSurveyAction } from '../../actions/Survey/UpdateSurveyAction';
import { AddSectionAction } from '../../actions/Survey/AddSectionAction';
import { ChangeResponseTypeAction } from '../../actions/Survey/ChangeResponseTypeAction';
import { SurveyViewCreator } from '../../view-creator/SurveyViewCreator';

export const useSurveyAdmin = () => {
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [reload, setReload] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newQuestionDialogVisible, setNewQuestionDialogVisible] =
    useState<boolean>(false);
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [, setSurveyId] = useState<number | null>(null);
  const [view, setView] = useState<SurveySummaryView>({ views: [] });
  const [sectionId, setSectionId] = useState<number>(0);
  const [newSectionDialogVisible, setNewSectionDialogVisible] =
    useState<boolean>(false);
  const [newSectionName, setNewSectionName] = useState<string>('');
  const [showAddOptionDialog, setShowAddOptionDialog] = useState<{
    showOptionDialog: boolean;
    questionIdx: number;
    sectionIdx: number;
  }>({ showOptionDialog: false, questionIdx: -1, sectionIdx: -1 });
  const [newOption, setNewOption] = useState<string>('');
  const [questionResponseType, setQuestionResponseType] = useState<{
    responseType: string;
    questionIdx: number;
  }>({ responseType: '', questionIdx: -1 });
  useEffect(() => {
    new SurveySummaryViewCreator().createView().then((view) => setView(view));
  }, [reload]);
  const cols: GridColDef[] = [
    { field: 'name', flex: 1, headerName: 'Survey Name' },
  ];

  const rowSelected = (params: any) => {
    setSurveyId(params.id);
    new SurveyViewCreator().CreateView(params.id).then((survey) => {
      setSurvey(survey);
      setDialogOpen(true);
    });
  };

  const handleAddOption = (sectionIdx: number, questionIndex: number) => {
    if (survey) {
      const addOptionAction = new AddOptionAction();
      const updatedSurvey = addOptionAction.Execute(
        survey,
        newOption,
        sectionIdx,
        questionIndex
      );
      setNewOption('');
      setShowAddOptionDialog({
        showOptionDialog: false,
        questionIdx: -1,
        sectionIdx: -1,
      });
      setSurvey(updatedSurvey);
    }
  };

  const handleAddQuestion = (sectionId: number) => {
    if (survey) {
      const addQuestionAction = new AddQuestionAction();
      const updatedSurvey = addQuestionAction.Execute(
        survey,
        sectionId,
        newQuestionText
      );
      setNewQuestionText('');
      setNewQuestionDialogVisible(false);
      setSurvey(updatedSurvey);
    }
  };

  const handleAddSection = () => {
    if (survey) {
      const addSectionAction = new AddSectionAction();
      const updatedSurvey = addSectionAction.Execute(survey, newSectionName);
      setSurvey(updatedSurvey);
      setNewSectionName('');
      setNewSectionDialogVisible(false);
    }
  };

  const handleOptionChange = (
    questionIndex: number,
    optionId: number,
    newValue: string
  ) => {
    if (survey) {
      const optionChangedAction = new OptionChangedAction();
      const updatedSurvey = optionChangedAction.Execute(
        survey,
        questionIndex,
        optionId,
        newValue
      );
      setSurvey(updatedSurvey);
    }
  };

  const handleCancelAddQuestion = () => {
    setNewQuestionDialogVisible(false);
  };

  const handleCancelAddSection = () => {
    setNewSectionDialogVisible(false);
  };
  const handleSaveSurvey = async () => {
    if (survey) {
      if (!survey.id) {
        const saveSurveyAction = new SaveSurveyAction();
        await saveSurveyAction.Execute(survey);
        setDialogOpen(false);
        setReload(true); // Optionally reload to ensure the latest data
      } else {
        const updateSurveyAction = new UpdateSurveyAction();
        await updateSurveyAction.Execute(survey);
        setDialogOpen(false);
        setReload(true); // Optionally reload to ensure the latest data
      }
    }
  };

  const handleRemoveOption = (questionIndex: number, optionId: number) => {
    if (survey) {
      const removeOptionAction = new RemoveOptionAction();
      const updatedSurvey = removeOptionAction.Execute(
        survey,
        questionIndex,
        optionId
      );
      setSurvey(updatedSurvey);
    }
  };

  const showNewQuestionDialog = (sectionId: number) => {
    setSectionId(sectionId);
    setNewQuestionDialogVisible(true);
  };

  const showNewSectionDialog = () => {
    setNewSectionDialogVisible(true);
  };
  const handleCancelAddSurvey = () => {
    setDialogOpen(false);
  };
  const setSurveyName = (e: any) => {
    if (!survey) {
      setSurvey({
        id: 0,
        title: '',
        sections: [],
      } as Survey);
    } else {
      survey.title = e.currentTarget.value;
      setSurvey({ ...survey });
    }
  };
  const handleCreateNewSurvey = () => {
    setSurvey({
      id: 0,
      title: '',
      sections: [],
    } as Survey);
    setDialogOpen(true);
  };

  const handleChangeResponseType = async (
    e: any,
    sectionIdx: number,
    qIndex: number
  ) => {
    if (survey) {
      const updatedSurvey = new ChangeResponseTypeAction().Execute(
        survey,
        e.target.value,
        sectionIdx,
        qIndex
      );
      setSurvey(updatedSurvey);
    }
  };

  return {
    view,
    survey,
    newQuestionDialogVisible,
    newQuestionText,
    dialogOpen,
    sectionId,
    cols,
    setSectionId,
    setDialogOpen,
    setNewQuestionText,
    handleAddOption,
    handleAddQuestion,
    handleRemoveOption,
    handleOptionChange,
    handleSaveSurvey,
    handleCreateNewSurvey,
    showNewQuestionDialog,
    handleCancelAddQuestion,
    handleCancelAddSection,
    handleCancelAddSurvey,
    showNewSectionDialog,
    newSectionDialogVisible,
    setNewSectionName,
    newSectionName,
    handleAddSection,
    setSurveyName,
    setNewOption,
    setShowAddOptionDialog,
    newOption,
    showAddOptionDialog,
    questionResponseType,
    setQuestionResponseType,
    handleChangeResponseType,
    rowSelected,
  };
};
