import React, { createContext, PropsWithChildren, useEffect } from 'react';
import { ErrorLogger } from '../api/ErrorLogger';
import { useCookies } from 'react-cookie';

const ErrorLoggerContext = createContext({});

export const ErrorLoggerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [cookies] = useCookies(['profile']);
  useEffect(() => {
    // Override console.error
    const originalConsoleError = console.error;
    const originalConsoleWarn = console.warn;
    // const originalConsoleDebug = console.debug;
    const originalConsoleInfo = console.info;

    console.error = (...args) => {
      originalConsoleError.apply(console, args);
      reportError('console.error', args);
    };

    console.warn = (...args) => {
      originalConsoleWarn.apply(console, args);
      reportError('console.warn', args);
    };

    console.info = (...args) => {
      originalConsoleInfo.apply(console, args);
      reportError('console.info', args);
    };

    // console.debug = (...args) => {
    //   originalConsoleDebug.apply(console, args);
    //   reportError('console.debug', args);
    // };

    // Global error handler
    const handleError = (event: any) => {
      reportError('Uncaught Error', event.error || event);
    };

    const handleRejection = (event: any) => {
      reportError('Unhandled Promise Rejection', event.reason);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);

    return () => {
      console.error = originalConsoleError;
      console.warn = originalConsoleWarn;
      console.info = originalConsoleInfo;
      // console.debug = originalConsoleDebug;
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, []);

  const reportError = async (type: any, errorDetails: any) => {
    // Send error to your backend API
    await new ErrorLogger().sendLogs({
      type,
      user: cookies.profile?.email ?? 'profile cookie not found',
      errorDetails,
      timestamp: new Date().toISOString(),
    });
  };

  return (
    <ErrorLoggerContext.Provider value={{}}>
      {children}
    </ErrorLoggerContext.Provider>
  );
};
